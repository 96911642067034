import React, { useState, useEffect } from 'react';
import styles from './soDetails.module.css';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST, DOMAIN_LIST } from '../../../constants/apiList';
import { toast } from 'react-toastify';
import Table from '../../common/Tables/Table';
import Loader from 'react-loader-spinner';
import dataResources from '../../../constants/dataResources';
import Iframe from "react-iframe";
import { store } from '../../../store';
import { getStaticUrl, STATIC_URL_KEYS } from '../../../constants/brandConfig';
import SoSummaryDetials from './Components/soSummaryDetail';

function SoDetials(props) {
    const [soDetails, setSoDetails] = useState([]);
    const [currentDetails, setCurrentDetails] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => setShowModal(false);
    const [rows, setRows] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [pdfLoader, setPdfLoader] = useState(false);
    const brand = store.getState().brandReducer ? store.getState().brandReducer.brand : "";
    const columns = [
        { title: "S No", field: "sno" },
        { title: "date", field: "dateIST" },
        { title: "Consultation Id", field: "consultId" },
        { title: "Mode", field: "pass" },
        { title: "Doctor", field: "doctorName" },
        { title: "Role", field: "role" },
        { title: "Status", field: "status" },
        { title: "Details", field: "details" },
        { title: "Summary", field: "showDetails" }
    ];

    const getPrescriptionUrl = (prescriptionId) => {
      setPdfLoader(true);
      axios.get(`${URL_LIST.GET_PDF_URL}/${prescriptionId}`)
      .then(res => {
        setPdfUrl(res?.data?.data?.pdfUrl);
        setShowPdfModal(true);
        setLoader(false);
      })
      .catch(err => {
        setPdfLoader(false);
        toast.error("Unable to get prescription");
        console.log("IN GET PDF ERROR", err);
      });
    }

    const searchSodetails = async (customerId) => {
        setLoader(true);
        setSoDetails(null);
        const payload = {
            "userId": props.customerId,
            "brand": brand
        }
        try {
          const res = await axios.post(URL_LIST.USER_SO_INFO, payload);
          if (res.data.status) {
              const transformedDetails = res.data.data.result && !Array.isArray(res.data.data.result) ? Object.values(res.data.data.result) : res.data.data.result;
              setSoDetails(transformedDetails);
              generateRows(transformedDetails);
          } else {
              setLoader(false);
              toast.error(res.data.message);
          }
      } catch (error) {
          toast.error("An error occurred while fetching SO details.");
      }
    }

    const generateRows = (soDetails) => {
        setLoader(true);
        const finalRows = [];
        let index = 1;
        for (const data of soDetails) {
            const sno = index;
            ++index;
            const finalRow = { ...data, sno };
            finalRow.dateIST = finalRow.dateIST.split('T')[0];
            finalRow.pass = (finalRow.pass === "true") ? 'PASS':'WALLET';
            finalRow.showDetails = <Button className="regular-button" size="sm" onClick={(evt) => showSummaryDetails(finalRow)}>Show More</Button>
            finalRow.details = finalRow.prescriptionId?<Button variant="info" onClick={evt=>getPrescriptionUrl(finalRow.prescriptionId)} size="sm">Show PDF</Button>:"Pending";
            finalRows.push(finalRow);
        }
        setRows(finalRows);
        setLoader(false);
    }

    const showSummaryDetails = (data) => {
        setCurrentDetails(data);
        setShowModal(true);
    }

    useEffect(() => {
      async function fetchData() {
        await searchSodetails(props.appointments);
    }
    fetchData();
    }, [props.appointments])

    return (
        <div className={styles["appointment-details"]}>
            <div className="page-heading" style={{ display: "flex" }}>
                SO details
            </div>
            <div className={styles["main-content"]}>
                {
                    loader ?
                        <Loader
                            type="ThreeDots"
                            width={40}
                            height={40}
                            color="#144166"
                        />
                        :
                        rows.length ?
                            <Table title="SO details" columns={columns} rows={rows} search={true} pageSize={rows.length > 8 ? 8 : rows.length} />
                            :
                            "NO DATA TO SHOW"
                }
            </div>
            <SoSummaryDetials currentDetails={currentDetails} show={showModal} closeModal={closeModal}/>
            <Modal
        className="pdf-modal"
        centered
        show={showPdfModal}
      >
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Iframe
              url={pdfUrl}
              width="700px"
              height="585px"
              id="myId"
              className="myClassname"
              display="initial"
            />
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: "10px", width: "max-content" }}
              onClick={() => {
                setPdfUrl("");
                setShowPdfModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
        </div>
    )
}

export default SoDetials;
