import React, {useState, useEffect} from 'react';
import styles from './AppointmentDetails.module.css';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST, DOMAIN_LIST } from '../../../constants/apiList';
import { toast } from 'react-toastify';
import Table from '../../common/Tables/Table';
import Loader from 'react-loader-spinner';
import dataResources from '../../../constants/dataResources';
import Iframe from "react-iframe";
import { store } from '../../../store';
import { getStaticUrl, STATIC_URL_KEYS } from '../../../constants/brandConfig';
import { getBrand } from '../../../helpers/index';

function AppointmentDetails(props) {
  const brand = getBrand();
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const columns = [
    { title: "S No", field: "sno" },
    { title: "Date", field: "date" },
    { title: "Appointment ID", field: "appointmentId" },
    { title: "Type", field: "type" },
    { title: "Concern", field: "concernTitle" },
    { title: "Doctor Assigned", field: "doctorAssigned" },
    { title: "Status", field: "statusTitle" },
    { title: "Image Uploaded", field: "imageUploaded" },
    { title: "Details", field: "details" },
    { title: "Upload", field: "uploadImages" },
  ];
  const getConcernTitle = (concern) => {
    let concernTitle = concern;
    concernTitle = concernTitle.replace("is_hc", "Hair");
    concernTitle = concernTitle.replace("is_sh", "Performance");
    concernTitle = concernTitle.replace("skin", "Skin");
    concernTitle = concernTitle.replace("weight", "Weight");
    concernTitle = concernTitle.replace("pcos", "PCOS");
    concernTitle = concernTitle.replace("beard", "Beard");
    concernTitle = concernTitle.replace("general", "General");
    concernTitle = concernTitle.replace("body-care", "Bodycare");
    concernTitle = concernTitle.replace("nutrition", "Nutrition");
    concernTitle = concernTitle.replace(",", ", ");
    return concernTitle;
  }
  const getPrescriptionUrl = (prescriptionId) => {
    setPdfLoader(true);
    axios.get(`${URL_LIST.GET_PDF_URL}/${prescriptionId}`)
    .then(res => {
      setPdfUrl(res?.data?.data?.pdfUrl);
      setShowPdfModal(true);
      setPdfLoader(false);
    })
    .catch(err => {
      setPdfLoader(false);
      toast.error("Unable to get prescription");
      console.log("IN GET PDF ERROR", err);
    });
  }
  const generateRows = (appointments) => {
    setLoader(true);
    const finalRows = [];
    let index = 1;
    for(const appt of appointments) {
      const uploadImageLink = getStaticUrl(STATIC_URL_KEYS.UPLOAD_IMAGE, { schedule_token: appt.scheduleToken });
      const sno = index;
      ++index;
      const finalRow = {...appt, sno};
      finalRow.concernTitle = getConcernTitle(finalRow.concern) || finalRow.concern;
      finalRow.statusTitle = dataResources.statusList[finalRow.status];
      finalRow.details = finalRow.prescriptionId?<Button variant="info" onClick={evt=>getPrescriptionUrl(finalRow.prescriptionId)} size="sm">Show PDF</Button>:"Pending";
      finalRow.uploadImages = <a href={uploadImageLink} target="_blank"><Button className="regular-button" size="sm">Upload Images</Button></a>;
      finalRows.push(finalRow);
    }
    setRows(finalRows);
    setLoader(false);    
  }
  useEffect(()=>{
    generateRows(props.appointments);
  },[props.appointments])
  return (
    <div className={styles["appointment-details"]}>
      <div className="page-heading" style={{display: "flex"}}>
        Appointment details
        {
          pdfLoader?
          <Loader type="ThreeDots" width={25} height={10} color="#144166" style={{marginLeft: "20px"}}/>
          :
          null
        }
      </div>
      <div className={styles["main-content"]}>
        {
          loader?
          <Loader
          type="ThreeDots"
          width={40}
          height={40}
          color="#144166"
          />
          :
          rows.length?
          <Table title="Appointments" columns={columns} rows={rows} search={true} pageSize={rows.length>8?8:rows.length}/>
          :
          "NO DATA TO SHOW"

        }
      </div>
      <Modal
        className="pdf-modal"
        centered
        show={showPdfModal}
      >
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Iframe
              url={pdfUrl}
              width="700px"
              height="585px"
              id="myId"
              className="myClassname"
              display="initial"
            />
            <Button
              variant="contained"
              color="secondary"
              style={{ marginTop: "10px", width: "max-content" }}
              onClick={() => {
                setPdfUrl("");
                setShowPdfModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </div>    
  )
}
export default AppointmentDetails;