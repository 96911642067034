import React, { Component } from "react";
import styles from "./styles.module.css";
import Loader from "react-loader-spinner";
import axios from "axios";
import { URL_LIST } from "./../../../constants/apiList";
import moment from "moment";
import { Button } from "react-bootstrap";
import Iframe from "react-iframe";
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { store } from "../../../store";
import { getBrand } from '../../../helpers/index';

export default class PatientHistory extends Component {
  constructor(props) {
    super(props);
    const brand = getBrand();
    this.state = {
      showLoader: false,
      history: [],
      pdf: "",
      showPdfModal: false,
      brand,
      email: "",
      message: "",
      csSecret: "i3vHp0JUnaO25ywR",
    };
  }
  orderPlacedShowClickHandler = (index, orderDetailsRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    const tempHistory = [...this.state.history];
    const tempItem = { ...item };
    tempItem.showing = true;
    tempHistory.splice(index, 1, tempItem);
    this.setState({ history: tempHistory });
    axios
      .post(URL_LIST.ORDER_DETAILS, orderDetailsRequestData)
      .then(response => {
        if (!response.data) {
          toast.error("cannot retrieve data");
          return;
        }
        const recommended = [];
        response.data.body.data.product_kits.map((prod, index) => {
          prod.product_items.map((singleItem, index) => {
            recommended.push(singleItem.name);
          });
        });
        response.data.body.data.product_items.map((prod, index) => {
          recommended.push(prod.name);
        });
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.showing = false;
        tempItem.recommendedProducts = recommended;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("OrderDetails API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.showing = false;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  formFilledShowClickHandler = (index, prescriptionRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    axios
      .post(URL_LIST.PRESCRIPTION_URL, prescriptionRequestData)
      .then(response => {
        if (!response.data) {
          toast.error("cannot retrieve data");
          return;
        }
        const recommended = [];
        response.data.body.data.product_kits.map((prod, index) => {
          prod.product_items.map((singleItem, index) => {
            recommended.push(singleItem.name);
          });
        });
        response.data.body.data.product_items.map((prod, index) => {
          recommended.push(prod.name);
        });
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.recommendedProducts = recommended;
        tempItem.form_details = response.data.body.data.form_details;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("PRESCRIPTION API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  formSubmittedShowClickHandler = (index, formSubmitRequestData) => {
    const item = this.state.history[index];
    if (item.showProducts) {
      const tempHistory = [...this.state.history];
      const tempItem = { ...item };
      tempItem.showProducts = false;
      tempHistory.splice(index, 1, tempItem);
      this.setState({ history: tempHistory });
      return;
    }
    axios
      .post(URL_LIST.FORM_SUBMIT, formSubmitRequestData)
      .then(response => {
        if (!response.data) {
          toast.error("cannot retrieve data");
          return;
        }
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempItem.form_details = response.data.form_details;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      })
      .catch(err => {
        console.log("FormSubmit API ERROR", err);
        const tempHistory = [...this.state.history];
        const tempItem = { ...tempHistory[index] };
        tempItem.showProducts = true;
        tempHistory.splice(index, 1, tempItem);
        this.setState({ history: tempHistory });
      });
  };
  prescriptionGeneratedShowClickHandler = (e, getPdfRequestData) => {
      axios.get(`${URL_LIST.GET_PDF_URL}/${getPdfRequestData?.prescriptionId}`)
      .then(response => {
        if(response?.data?.data?.pdfUrl) {
          this.setState({ pdf: response?.data?.data?.pdfUrl, showPdfModal: true });
        }
        else {
          toast.error("Unable to fetch Prescription.");
        }
      })
      .catch(err => {
        console.log("IN GET PDF ERROR", err);
        toast.error("Unable to show Prescription. Try again Later");
      });
  };
  renderOrderPlacedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    let orderDetailsRequestData = {
      form: item.category ? item.category : "OF",
      order_id: item.form_id ? item.form_id : item.order_id,
      event_type: item.event_type,
      brand: this.state.brand,
      cs_secret: this.state.csSecret,
    };
    return (
      <div className={`${styles["history-card"]} ${styles["order-placed"]}`}>
        <div className={styles["history-card-title"]}>
          {this.getDisplayTitle(item.event_type)} {item.order_id}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.orderPlacedShowClickHandler(index, orderDetailsRequestData)
            }
          >
            {item.showProducts ? (
              "Hide"
            ) : item.showing ? (
              <Loader type="TailSpin" color="#00BFFF" height={20} width={20} />
            ) : (
              "Show"
            )}
          </Button>
        </div>
        <div className={styles["history-date-container"]}>{createdDate}</div>
        <div className={styles["history-order-status"]}>
          Order Status:{" "}
          <span className={styles["highlight"]}>
            {this.getDisplayTitle(item.order_status)}
          </span>
        </div>
        {item.type === "RX" ? (
          <div>
            Appointment Schedule:{" "}
            <span className={styles["highlight"]}>{item.appointment_schedule}</span>
          </div>
        ) : null}
        <div>
          Ordered on <span className={styles["highlight"]}>{createdDate}</span>
        </div>
        {item.type === "RX" && item.doctor_assigned ? (
          <div>
            The consultation was assigned to{" "}
            <span className={styles["highlight"]}>{item.doctor_assigned}</span>
          </div>
        ) : null}
        {item.showProducts ? (
          <>
            <div style={{ marginTop: "10px" }}>
              <span className={styles["highlight"]}>Recommended Products:</span>
            </div>
            <div>
              {item.recommendedProducts && item.recommendedProducts.length > 0
                ? item.recommendedProducts &&
                  item.recommendedProducts.join(", ")
                : "No Product to show."}
            </div>
          </>
        ) : null}
      </div>
    );
  };
  renderQNA = form_details => {
    let data = {
      Hair: form_details.Hair,
      Weight: form_details.Weight,
      Skin: form_details.Skin,
      Performance: form_details.Performance,
      Fitness: form_details.Fitness,
      Nutrition: form_details.Nutrition,
      Stress: form_details.Stress,
      Sleep: form_details.Sleep,
      Lifestyle: form_details.Lifestyle,
      Wellness: form_details.Wellness,
      Health: form_details.Health
    };
    let renderView = [];
    for (let [key, value] of Object.entries(data)) {
      if (value && Object.keys(value).length > 0) {
        let qnaHead = (
          <div>
            <u>
              <b>{key}</b>
            </u>
          </div>
        );
        renderView.push(qnaHead);
        for (let [key2, value2] of Object.entries(value)) {
          if (Object.keys(value).length > 0) {
            let qnaView = (
              <div>
                <div>Q :{key2}</div>
                <div>
                  <span className={styles["ans"]}>A : </span> {value2}
                </div>
                <br />
              </div>
            );
            renderView.push(qnaView);
          }
        }
      }
    }
    return renderView;
  };
  renderFormFilledCard = (item, index) => {
    let prescriptionRequestData = {
      cs_secret: this.state.csSecret,
      form: item.category ? item.category : "OF",
      id: item.form_id ? item.form_id : item.order_id
    };
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className={`${styles["history-card"]} ${styles["form-filled"]}`}>
        <div className={styles["history-card-title"]}>
          {displayTitle}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.formFilledShowClickHandler(index, prescriptionRequestData)
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </Button>
        </div>
        <div className={styles["history-date-container"]}>{createdDate}</div>
        <div>
          Filled the {displayTitle.slice(0, displayTitle.indexOf(" "))} Form on{" "}
          <span className={styles["highlight"]}>{createdDate} </span>
          and was recommended.
          <br /> The consultation was assigned to{" "}
          <span className={styles["highlight"]}>{item.doctor_assigned}</span>.
        </div>
        {item.showProducts ? (
          <div>
            <div>
              <span className={styles["highlight"]}>Recommended Products:</span>
            </div>
            {item.recommendedProducts && item.recommendedProducts.length > 0
              ? item.recommendedProducts.join(",")
              : "No Product to show."}
            <div>
              <span className={styles["highlight"]}>QNA</span>
            </div>
            {item.form_details ? (
              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                {this.renderQNA(item.form_details)}
              </div>
            ) : (
              "No QNA available!"
            )}
          </div>
        ) : null}
      </div>
    );
  };
  renderFormSubmittedCard = (item, index) => {
    let formSubmitRequestData = {
      brand: this.state.brand,
      cs_secret: this.state.csSecret,
      formID: item.form_id
    };
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className={`${styles["history-card"]} ${styles["form-submitted"]}`}>
        <div className={styles["history-card-title"]}>
          {displayTitle}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.formSubmittedShowClickHandler(index, formSubmitRequestData)
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </Button>
        </div>
        <div className={styles["history-date-container"]}>{createdDate}</div>
        <div>
          Filled the Form on <span className={styles["highlight"]}>{createdDate} </span>
        </div>
        {item.showProducts ? (
          <div>
            <div>
              <span className={styles["highlight"]}>QNA</span>
            </div>
            {item.form_details ? (
              <div style={{ maxHeight: "200px", overflow: "auto" }}>
                {this.renderQNA(item.form_details)}
              </div>
            ) : (
              "No QNA available!"
            )}
          </div>
        ) : null}
      </div>
    );
  };
  renderAppointmentRescheduleCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    return (
      <div className={`${styles["history-card"]} ${styles["appointment-rescheduled"]}`}>      
        <div className={styles["history-card-title"]}>{displayTitle}</div>
        <div className={styles["history-date-container"]}>{createdDate}</div>
        <div>
          Rescheduled By:{" "}
          <span className={styles["highlight"]}>
            {this.getDisplayTitle(item.rescheduleBy)}
          </span>
        </div>
        <div>
          New Appointment:{" "}
          <span className={styles["highlight"]}>{item.new_appointment}</span>
        </div>
        <div>
          Previous Appointment:{" "}
          <span className={styles["highlight"]}>{item.previous_appointmnet}</span>
        </div>
      </div>
    );
  };
  renderAppointmentBookedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);

    return (
      <div className={`${styles["history-card"]} ${styles["appointment-booked"]}`}>
        <div className={styles["history-card-title"]}>{displayTitle}</div>
        <div className={styles["history-date-container"]}>{createdDate}</div>
        <div>
          Appointment Schedule:{" "}
          <span className={styles["highlight"]}>{item.appointment_schedule}</span>
        </div>
      </div>
    );
  };
  renderPrescriptionGeneratedCard = (item, index) => {
    const createdDate = moment(item.created_dttm)
      .utcOffset(330)
      .format("DD MMM YYYY  hh:mm A");
    const displayTitle = this.getDisplayTitle(item.event_type);
    let getPdfRequestData = {
      secret: "uebDOKSGCP",
      prescriptionId: item.prescription_id,
      brand: this.state.brand
    };

    return (
      <div className={`${styles["history-card"]} ${styles["prescription-generated"]}`}>
        <div className={styles["history-card-title"]}>
          {displayTitle}
          <Button
            variant="info"
            size="sm"
            onClick={evt =>
              this.prescriptionGeneratedShowClickHandler(
                index,
                getPdfRequestData
              )
            }
          >
            {item.showProducts ? "Hide" : "Show"}
          </Button>
        </div>
        <div className={styles["history-date-container"]}>{createdDate}</div>
        <div>Prescription has been successfully generated.</div>
      </div>
    );
  };
  renderCard = (item, index) => {
    switch (item.event_type) {
      case "order_placed":
        return this.renderOrderPlacedCard(item, index);
      case "subscription_order_placed":
        return this.renderOrderPlacedCard(item, index);
      case "hair_form_filled":
        return this.renderFormFilledCard(item, index);
      case "performance_form_filled":
        return this.renderFormFilledCard(item, index);
      case "form_submmited":
        return this.renderFormSubmittedCard(item, index);
      case "form_submitted":
        return this.renderFormSubmittedCard(item, index);
      case "appointment_rescheduled":
        return this.renderAppointmentRescheduleCard(item, index);
      case "appointment_booked":
        return this.renderAppointmentBookedCard(item, index);
      case "prescription_generated":
        return this.renderPrescriptionGeneratedCard(item, index);
      case "diet_plan_created":
        return this.renderPrescriptionGeneratedCard(item, index);
      default:
        return (
          <div style={{ margin: "2px", border: "1px solid black" }}>
            {this.getDisplayTitle(item.event_type)}
          </div>
        );
    }
  };
  getDisplayTitle = eventType => {
    let words = eventType.split("_");
    let title = "";
    words.forEach((word, index) => {
      if (word && word.length > 0)
        title += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });
    return title;
  };
  loadHistory = () => {
    this.setState({ showLoader: true, message: "" });
    const getHistoryRequestData = {
      brand: this.state.brand,
      cs_secret: this.state.csSecret,
      email: this.state.email,
    };
    axios.post(URL_LIST.EVENT_INFORMATION, getHistoryRequestData)
    .then(res => {
      const sortedEvents = res.data.body.data.sort((a, b) => {
        return new Date(b.created_dttm) - new Date(a.created_dttm);
      });
      this.setState({ showLoader: false, history: sortedEvents, message: "NO DATA FOUND!!!" });
    })
    .catch(err => {
      console.log("ERROR IN GET HISTORY", err);
      this.setState({ showLoader: false });
    });
  };
  render() {
    const renderHistory = this.state.history && this.state.history.length>0;
    return (
      <div className={styles["patient-history"]}>
        <div className={styles["patient-history-main"]}>
          <div className="page-heading" style={{minWidth: "200px"}}>Patient History</div>
          <div className={styles["form-section"]}>
            <input type="text" className="form-control" placeholder="Email ID" onChange={evt=>this.setState({email: evt.target.value})} value={this.state.email} style={{width: "300px"}}/>            
            <Button
              className="regular-button"
              disabled={!this.state.email}
              onClick={this.loadHistory}
            >
              Search
            </Button>
          </div>
          {
            this.state.showLoader?
            <Loader type="ThreeDots" width={30} height={30} color="#144166" style={{marginLeft: "20px"}}/>
            :
            renderHistory?
            <div className={styles["history-cards-section"]}>
              {
                this.state.history.map((item,index)=>{
                  return this.renderCard(item, index);
                })
              }
            </div>
            :
            <div style={{color: 'rgb(180,180,180)', marginTop: '20px'}}>{this.state.message}</div>
          }

        </div>
        <Modal
          className="xyz"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showPdfModal}
          onHide={() => this.setState({ pdf: "", showPdfModal: false })}
        >
          <Modal.Body className={styles["mdl-body"]}>
            {!this.state.pdf && (
              <div className={styles["loader-cm"]}>
                Loading Prescription...Please Wait
                <div className={styles["Loader-box"]}>
                  <Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </div>
              </div>
            )}
            <Iframe
              url={this.state.pdf}
              width="700px"
              height="700px"
              id="myId"
              className="myClassname"
              display="initial"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ pdf: "", showPdfModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
